import styled from "styled-components";
import { useLogEvent } from "../hooks/useLogEvent";
import { device } from "../styles/stylesConfig";

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
`

const ContentContainer = styled.div`
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    & > h3 {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        text-align: center;

        @media ${device.md} {
            font-size: 10px;
            margin-bottom: 15px;
        }

        @media ${device.sm} {
            font-size: 8px;
        }
    }

    @media ${device.md} {
        width: 100%;
        flex-direction: column;
    }

`

const Button = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;

    & > h3 {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        font-size: 11px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        text-align: center;

        &:hover {
            text-decoration: underline !important; 
        }

        & > span {
            font-family: 'Montserrat', sans-serif;
            color: #fff;
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.1px;
            text-transform: uppercase;
            text-align: center;


            @media ${device.md} {
                font-size: 10px;
            }

            @media ${device.sm} {
                font-size: 8px;
            }

        } 

        @media ${device.md} {
            font-size: 10px;
        }

        @media ${device.sm} {
            font-size: 8px;
        }
    }
`

export const Rights = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <Container>

            <ContentContainer>

                <h3 className="utils__fade-in-fast">Wasabi Fest © {new Date().getFullYear()} - Todos los derechos reservados</h3>

                <Button
                    className='rights__button utils__fade-in-fast'
                    href="https://www.instagram.com/batata.agencia"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                        handleLogEvent('click_boton_batata');
                    }}
                >

                    <h3>Diseñado y desarrollado por <span>Batata © Agencia Digital</span></h3>

                </Button>

            </ContentContainer>

        </Container>

    )
}

export default Rights;