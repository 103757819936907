import { getRandomArrayValue } from "../utils/Utilities";

export const getMomentImages = async (
    setMomentImages: React.Dispatch<React.SetStateAction<{
        shots: string[];
        pictures: string[];
        glass: string[];
    }>>) => {

    const shotsArray = [
        `${process.env.REACT_APP_CDN}/moments/shots/shots1.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots2.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots3.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots5.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots7.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots8.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots9.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots12.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots13.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots14.webp`,
        `${process.env.REACT_APP_CDN}/moments/shots/shots15.webp`,
    ]

    const picturesArray = [
        `${process.env.REACT_APP_CDN}/moments/pictures/fotos1.webp`,
        `${process.env.REACT_APP_CDN}/moments/pictures/fotos3.webp`,
        `${process.env.REACT_APP_CDN}/moments/pictures/fotos4.webp`,
        `${process.env.REACT_APP_CDN}/moments/pictures/fotos5.webp`,
        `${process.env.REACT_APP_CDN}/moments/pictures/fotos6.webp`,
        `${process.env.REACT_APP_CDN}/moments/pictures/fotos7.webp`,
    ]

    const glassArray = [
        `${process.env.REACT_APP_CDN}/moments/glass/vaso0.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso4.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso5.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso6.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso7.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso9.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso10.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso16.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso18.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso20.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso21.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso22.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso23.webp`,
        `${process.env.REACT_APP_CDN}/moments/glass/vaso24.webp`,
    ]

    const shotsElement = getRandomArrayValue(shotsArray);

    const picturesElement = getRandomArrayValue(picturesArray);

    const glassElement = getRandomArrayValue(glassArray);

    setMomentImages({
        shots: [
            shotsArray[shotsElement]
        ],
        pictures: [
            picturesArray[picturesElement]
        ],
        glass: [
            glassArray[glassElement]
        ],
    })

}