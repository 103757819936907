import { styled } from "styled-components";
import { device } from "../styles/stylesConfig";
import { Typography } from "./atoms/Typography";
import { Button } from "./molecules/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "./molecules/Input";
import { getAddedDate, getHexadecimalByPrimaryColorState } from "../utils/Utilities";
import { scrollFadeInBottom, scrollFadeInCenter, scrollFadeInFastCenter, scrollFadeInFastLeft, scrollFadeInFastRight, scrollFadeInFastTop, scrollFadeInLeft, scrollFadeInRight, scrollFadeInTop } from "../effects/Observers";
import { handleFormConfirm } from "../helper/NewsletterHelper";

const ShadowContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);

`

const ModalCardContainer = styled.div`
    width: 60%;
    max-width: 90%;
    max-height: 75%;
    box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 50px;
    position: relative;
    background-color: #111;
    position: relative;

    @media ${device.lg} {
        width: 75%;
    } 

    @media ${device.md} {
        width: 95%;
        max-height: 80%;
    } 

    @media ${device.sm} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media ${device.xs} {
        padding-left: 20px;
        padding-right: 20px;
    }

    & > h1 {
        text-transform: uppercase;
        padding: 30px 0px 10px;
        text-align: center;

        @media ${device.lg} {
            font-size: 16px;
        } 

        @media ${device.md} {
            font-size: 14px;
        } 

        @media ${device.sm} {
            font-size: 12px;
            padding: 30px 0px 0px;
        }

        @media ${device.xs} {
            font-size: 10px;
        }

    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media ${device.sm} {
        margin-bottom: 0px;

        & > button {
            margin-top: 4px !important;
        }
    }

    & > h1 {
        width: 90%;
        font-weight: bold;
        text-transform: uppercase;
        
        @media ${device.lg} {
            font-size: 30px;
        } 

        @media ${device.md} {
            font-size: 28px;
        } 

        @media ${device.sm} {
            font-size: 20px;
        }

        @media ${device.xs} {
            width: 85%;
            font-size: 18px;
        }


    }

    & > button {
        
        &:hover {
            border: 1px solid transparent;
        }
        
        & > img {
            width: 25px !important;
            height: 25px !important;
            
            @media ${device.md} {
                width: 20px !important;
                height: 20px !important;
            }

        }

    }
`

const UpdateContainer = styled.div`
    max-width: 500px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: end;
    align-self: center;
    margin-bottom: 30px;

    @media ${device.lg} {

        & > div {
            font-size: 14px;
            font-weight: lighter;
            margin-top: 40px;

        }
    }

    @media ${device.md} {
        margin-bottom: 20px;
    }

    @media ${device.sm} {
        margin-bottom: 0px;

        & > div {
            font-size: 12px;
            font-weight: lighter;
        }
    }
    
`


interface Props {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NewsletterModal = ({ setVisible }: Props) => {

    const { primaryColor } = useSelector((state: any) => state.ui);

    const [form, setForm] = useState<string>('');

    const [fieldIncomplete, setFieldIncomplete] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {

        localStorage.setItem("primaryColor", `${primaryColor}`);

        scrollFadeInTop();
        scrollFadeInFastTop();

        scrollFadeInBottom();

        scrollFadeInCenter();
        scrollFadeInFastCenter();

        scrollFadeInRight();
        scrollFadeInFastRight();

        scrollFadeInLeft();
        scrollFadeInFastLeft();

    }, [])


    return (

        <ShadowContainer >

            <ModalCardContainer
                className="utils__fade-in-fast-top"
                style={{
                    border: `1px solid ${getHexadecimalByPrimaryColorState(primaryColor)}`

                }}
            >

                <HeaderContainer

                >

                    <Typography
                        className="utils__fade-in-fast-center"
                        size='42px'
                        bold
                        style={{
                            color: getHexadecimalByPrimaryColorState(primaryColor)
                        }}
                    >
                        ¿Te cansa perderte siempre la fiesta más picante?
                    </Typography>

                    <Button
                        className="utils__fade-in-fast-center"
                        type="icon"
                        icon={`${process.env.REACT_APP_CDN}/general/cerrar.png`}
                        onClick={() => {

                            localStorage.setItem('newsletterExpire', getAddedDate(3).padEnd(17, '0'));

                            setVisible(false)

                        }}
                        style={{
                            alignSelf: 'start',
                            marginTop: 15,
                        }}
                    />


                </HeaderContainer>


                <Typography
                    className="utils__fade-in-fast-center"
                    size='18px'
                    bold
                    style={{
                        color: getHexadecimalByPrimaryColorState(primaryColor)
                    }}
                >
                    ¡Suscribite ahora mismo para ser el primero en enterarte de todo!
                </Typography>

                <UpdateContainer
                    className="utils__fade-in-fast-center"
                >

                    <Input
                        style={{ backgroundColor: 'white' }}
                        type="text"
                        label={''}
                        value={form}
                        placeholder="Escribí tu mail acá"
                        onChange={(e) => {

                            if (setFieldIncomplete && fieldIncomplete) { setFieldIncomplete(false); }

                            setForm(e.target.value.toLowerCase());

                        }}
                    />

                    <Button
                        type="icon"
                        icon={`${process.env.REACT_APP_CDN}/general/flecha.png`}
                        onClick={() => { handleFormConfirm({ form, setForm, setFieldIncomplete, dispatch, setVisible }) }}
                        style={{
                            backgroundColor: getHexadecimalByPrimaryColorState(primaryColor),
                            minWidth: 46,
                            height: 46,
                            borderRadius: 5,
                            marginLeft: 5
                        }}
                    />

                </UpdateContainer>


            </ModalCardContainer>


        </ShadowContainer>

    )
}
