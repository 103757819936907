import styled from "styled-components"
import { device, fonts } from "../styles/stylesConfig"
import { Suspense, lazy, useEffect, useState } from "react"
import { TextHovered } from "./TextHovered"
import { useSelector } from "react-redux"
import { getHexadecimalByPrimaryColorState } from "../utils/Utilities"
import MemorizedTransitionImages from "./TransitionImages"
import { getMomentImages } from "../helper/MomentsHelper"

const TextRevealed = lazy(() => import("./TextRevealed"));

const Container = styled.div`
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #111;
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 100px 0 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    
    @media ${device.sm} {
        margin: 20px;
    }

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 42px;
        text-transform: uppercase;
        color: #BAF18D;
        letter-spacing: 5px;
        text-align: center;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.xs} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        margin-bottom: 50px;
    }

`

const InfoContainer = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-area: center;
    gap: 0px;

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }


`

const Card = styled.button`
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    box-shadow: 4px 4px 12px #0000001f;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        transition: opacity 1s ease-in-out;
        z-index: 0;
    }

    &::before {
        transition: all .3s ease;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0);
    }

    & > div:nth-child(1) {
        opacity: 1;
        visibility: visible;
        position: relative;
    }

    & > div:nth-child(2) {
        transition: all 0.3s ease !important;
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }

    @media ${device.md} {

        & > div:nth-child(1) {
            opacity: 0;
            visibility: hidden;
            position: absolute;
        }

        & > div:nth-child(2) {
            transition: all 0.3s ease !important;
            opacity: 1;
            visibility: visible;
            position: relative;
        }
    }

    &:hover{

        & > div:nth-child(1) {
            opacity: 0;
            visibility: hidden;
            position: absolute;
        }

        & > div:nth-child(2) {
            opacity: 1;
            visibility: visible;
            position: relative;
        }

    }

    @media ${device.sm} {
        height: 400px;
    }

    @media ${device.xs} {
        height: 300px;
    }
`

const CardTitle = styled.h2`
    font-size: 40px;
    color: #BAF18D;
    margin-bottom: 50px;
    padding: 0 10%;
    z-index: 1;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    opacity: 1;
    font-family: ${fonts.secondary};

    @media ${device.lg} {
        font-size: 32px;
    }

    @media ${device.md} {
    }

    @media ${device.sm} {
        font-size: 30px;

    }

    @media ${device.xs} {
        font-size: 24px;

    }
`

const CardDescription = styled.p`
    width: 100%;
    font-size: 32px;
    color: #BAF18D;
    z-index: 1;
    padding: 0 10%;
    text-align: center;
    letter-spacing: 3px;
    opacity: 1;
    font-family: ${fonts.secondary};

    @media ${device.xl} {
    }

    @media ${device.lg} {
        font-size: 26px;
    }

    @media ${device.md} {
    }

    @media ${device.sm} {
        font-size: 20px;

    }

    @media ${device.xs} {
        font-size: 16px;

    }
`

export const Moments = () => {

    const { primaryColor } = useSelector((state: any) => state.ui);

    const [momentImages, setMomentImages] = useState<{ shots: string[], pictures: string[], glass: string[] }>({
        shots: [],
        pictures: [],
        glass: [],
    });

    useEffect(() => {

        getMomentImages(setMomentImages);

    }, []);

    return (

        <Container>

            <Target id="moments" />

            <ContentContainer>

                <TitleContainer>

                    <h1
                        style={{
                            color: getHexadecimalByPrimaryColorState(primaryColor),
                            fontFamily: fonts.secondary
                        }}
                        className="utils__fade-in-fast-top"
                    >
                        MOMENTOS WASABI
                    </h1>

                </TitleContainer>

                <InfoContainer>

                    <Card>

                        <Suspense fallback={<></>}>

                            <TextRevealed text="SHOTS" />

                        </Suspense>

                        <TextHovered>

                            <CardTitle
                                style={{
                                    color: getHexadecimalByPrimaryColorState(primaryColor)
                                }}

                            >
                                Shots
                            </CardTitle>

                            <CardDescription
                                style={{
                                    color: getHexadecimalByPrimaryColorState(primaryColor)
                                }}
                            >
                                Shots gratis para todxs
                            </CardDescription>

                        </TextHovered>

                        {
                            momentImages.shots ? (

                                momentImages.shots.map((image, index) => (

                                    <MemorizedTransitionImages
                                        key={image + index.toString()}
                                        src={image}
                                        index={index}
                                        currentImageIndex={0}
                                    />

                                ))

                            ) : <></>
                        }

                    </Card>

                    <Card>

                        <Suspense fallback={<></>}>

                            <TextRevealed text="CABINA DE FOTOS" />

                        </Suspense>

                        <TextHovered>

                            <CardTitle
                                style={{
                                    color: getHexadecimalByPrimaryColorState(primaryColor)
                                }}

                            >
                                Cabina de fotos
                            </CardTitle>

                            <CardDescription
                                style={{
                                    color: getHexadecimalByPrimaryColorState(primaryColor)
                                }}

                            >Llevate tu<br />foto de recuerdo
                            </CardDescription>

                        </TextHovered>

                        {
                            momentImages.pictures ? (

                                momentImages.pictures.map((image, index) => (

                                    <MemorizedTransitionImages
                                        key={image + index.toString()}
                                        src={image}
                                        index={index}
                                        currentImageIndex={0}
                                    />

                                ))

                            ) : <></>
                        }

                    </Card>


                    <Card>

                        <Suspense fallback={<></>}>

                            <TextRevealed text="VASO WASABI" />

                        </Suspense>

                        <TextHovered>

                            <CardTitle
                                style={{
                                    color: getHexadecimalByPrimaryColorState(primaryColor)
                                }}

                            >
                                Vaso Wasabi
                            </CardTitle>

                            <CardDescription
                                style={{
                                    color: getHexadecimalByPrimaryColorState(primaryColor)
                                }}

                            >
                                Con la compra de tu bebida + $3.500
                            </CardDescription>

                        </TextHovered>

                        {
                            momentImages.glass ? (

                                momentImages.glass.map((image, index) => (

                                    <MemorizedTransitionImages
                                        key={image + index.toString()}
                                        src={image}
                                        index={index}
                                        currentImageIndex={0}
                                    />

                                ))

                            ) : <></>
                        }

                    </Card>

                </InfoContainer>

            </ContentContainer>

        </Container>

    )
}

export default Moments