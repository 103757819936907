import { memo } from "react"

interface Props {
    src: string;
    index: number;
    currentImageIndex: number;
}

const TransitionImages = ({ src, index, currentImageIndex }: Props) => {

    return (

        <img
            loading="lazy"
            src={src}
            alt={index.toString()}
            className={index === currentImageIndex ? 'utils__visible' : 'utils__hidden'}
        />

    )
}

const MemorizedTransitionImages = memo(TransitionImages);

export default MemorizedTransitionImages;