import React from "react";


export const TextHovered = ({
    children,
}: {
    children?: React.ReactNode;
}) => {

    return (

        <div
            style={{
                width: "100%",
                height: "100%",
            }}
            className="absolute bg-[#000000a3] z-20 flex flex-col items-center justify-center"
        >

            {children}

        </div>


    );
};
