import styled, { css } from "styled-components"
import { colors } from "../../styles/stylesConfig"
import { CSSProperties, ReactNode } from "react"
import { getFullDate } from "../../utils/Utilities"
import { useSelector } from "react-redux"

const InputContainer = styled.div<{ incomplete: string }>`
    width: 100%;
    border-radius: 8px;
    opacity: 1;
    margin-top: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;

    ${props => props.incomplete !== '' && css`

        & > :nth-child(2) {
            border-color: ${colors.danger} !important;
        }
            
    `}
`

const InputLabel = styled.h2<{ theme?: string }>`
    position: absolute;
    top: -25px;
    left: 5px;
    font-weight: normal;
    font-size: 13px;
    color: ${props => props.theme === 'light' ? colors.light : colors.primary_dark};
`

const InputDescription = styled.h3<{ theme?: string }>`
    font-weight: normal;
    font-size: 12px;
    color: ${props => props.theme === 'light' ? colors.light : colors.primary_dark};
    margin-top: 15px;
`

const InputIncomplete = styled.h3`
    font-weight: 600;
    font-size: 12px;
    color: ${colors.danger};
    margin-top: 5px;
    margin-left: 5px;
`

const InputComponent = styled.input<{ theme?: string }>`
    height: 46px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #B7BABC;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    color: ${props => props.theme === 'light' ? colors.light : colors.dark};
    font-family: 'Montserrat';
    font-weight: normal !important;

    &:focus{
        outline: none;
        border: 1px solid ${colors.grey_dark};
    }

`

const Select = styled.select<{ state: string, theme?: string }>`
    height: 46px;
    width: 100%;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #B7BABC;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    padding-right: 20px;
    
    &:focus{
        outline: none;
        border: 1px solid ${colors.primary};
    }

    & > option {
        background-color: white;
        color: black;
    }

    ${props => props.state !== '' && css`
        background-color: ${props.state} !important;
        color: ${props.theme === 'light' ? colors.light : colors.dark};
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`

const InputAreaComponent = styled.textarea<{ theme?: string }>`
    width: 100%;
    min-height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #B7BABC;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    padding-top: 15px;
    resize: none;
    color: ${props => props.theme === 'light' ? colors.light : colors.primary_dark};

    &:focus{
        outline: none;
        border: 1px solid ${colors.primary};
    }
`

const RadioInputComponent = styled.input<{ theme?: string }>`
    height: 20px;
    width: 100%;
    padding-left: 20px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #B7BABC;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    white-space:'nowrap';
    overflow: 'hidden';
    text-overflow: 'ellipsis';
    padding-right: 20px;
    color: ${props => props.theme === 'light' ? colors.light : colors.primary_dark};

    &:focus{
        outline: none;
        border: 1px solid ${colors.primary};
    }
`

const ReadOnlyComponent = styled.h1<{ theme?: string }>`
    width: 100%;
    padding: 16px 20px;
    border-radius: 8px;
    box-shadow: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
    border: 1px solid #B7BABC;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    white-space: normal;
    color: ${props => props.theme === 'light' ? colors.light : colors.primary_dark};
`


interface Props {
    type: 'text' | 'number' | 'select' | 'date' | 'textArea' | 'radio' | 'password' | 'file'
    theme?: 'dark' | 'light'
    label: string
    value: any
    state?: string;
    accept?: string;
    incomplete?: boolean
    placeholder?: string
    description?: string
    readOnly?: boolean
    style?: CSSProperties
    children?: ReactNode
    min?: number
    clearButton?: JSX.Element
    reference?: any
    pattern?: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
    onClick?: React.MouseEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

export const Input = ({ type, theme, label, value, description, placeholder, pattern, children, style, state, reference, incomplete, readOnly, min, accept, clearButton, onChange, onBlur, onFocus, onKeyDown, onClick }: Props) => {

    const { primaryColor } = useSelector((state: any) => state.ui);

    const setInput = () => {

        switch (type) {
            case 'text':
                return (

                    <>

                        <InputComponent
                            ref={reference}
                            type={type}
                            placeholder={placeholder}
                            id={label + getFullDate()}
                            name={label + getFullDate()}
                            value={value}
                            disabled={readOnly}
                            pattern={pattern}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            onChange={(e: any) => onChange(e)}
                            theme={theme}
                            style={{
                                paddingRight: clearButton ? 50 : 20
                            }}
                        />

                        {
                            clearButton && clearButton
                        }

                    </>


                )

            case 'password':
                return (

                    <>

                        <InputComponent
                            ref={reference}
                            type={type}
                            placeholder={placeholder}
                            id={label + getFullDate()}
                            name={label + getFullDate()}
                            value={value}
                            pattern={pattern}
                            disabled={readOnly}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            onChange={(e: any) => onChange(e)}
                            theme={theme}
                            style={{
                                paddingRight: clearButton ? 50 : 20
                            }}
                        />

                        {
                            clearButton && clearButton
                        }

                    </>


                )

            case 'number':
                return (
                    <InputComponent
                        ref={reference}
                        type="number"
                        placeholder={placeholder}
                        id={label + getFullDate()}
                        value={value}
                        name={label + getFullDate()}
                        disabled={readOnly}
                        pattern={pattern}
                        onChange={(e: any) => onChange(e)}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        minLength={1}
                        min={min ?? 1}
                        theme={theme}
                    />
                )
            case 'select':
                return (
                    <Select
                        ref={reference}
                        state={state ? JSON.parse(state).color : ''}
                        onChange={(e: any) => onChange(e)}
                        value={value}
                        disabled={readOnly}
                        id={label + getFullDate()}
                        name={label + getFullDate()}
                        style={{
                            whiteSpace: readOnly ? 'nowrap' : undefined,
                            overflow: readOnly ? 'hidden' : undefined,
                            textOverflow: readOnly ? 'ellipsis' : undefined,
                            paddingRight: readOnly ? 20 : 0,
                        }}
                        theme={theme}>
                        {children}
                    </Select>
                )
            case 'date':
                return (
                    <InputComponent
                        ref={reference}
                        type="date"
                        placeholder={placeholder}
                        value={value}
                        id={label + getFullDate()}
                        name={label + getFullDate()}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        disabled={readOnly}
                        onChange={(e: any) => onChange(e)}
                        theme={theme}
                    />
                )
            case 'textArea':
                return (
                    <InputAreaComponent
                        ref={reference}
                        placeholder={placeholder}
                        id={label !== '' ? label + getFullDate() : getFullDate()}
                        name={label !== '' ? label + getFullDate() : getFullDate()}
                        value={value}
                        disabled={readOnly}
                        onChange={(e: any) => onChange(e)}
                        theme={theme}
                    />
                )
            case 'radio':
                return (
                    <RadioInputComponent
                        ref={reference}
                        type="radio"
                        placeholder={placeholder}
                        id={label + getFullDate()}
                        name={label}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        checked={value}
                        disabled={readOnly}
                        onChange={(e: any) => onChange(e)}
                        onClick={(e: any) => onClick && onClick(e)}
                        theme={theme}
                    />
                )
            case 'file':
                return (

                    <>

                        <InputComponent
                            ref={reference}
                            type={type}
                            accept={accept}
                            placeholder={placeholder}
                            id={label + getFullDate()}
                            name={label + getFullDate()}
                            value={value}
                            disabled={readOnly}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            onChange={(e: any) => onChange(e)}
                            theme={theme}
                            style={{
                                paddingRight: clearButton ? 50 : 20
                            }}
                        />

                        {
                            clearButton && clearButton
                        }

                    </>


                )
        }

    }

    return (

        <InputContainer style={{ ...style }} incomplete={incomplete ? incomplete.toString() : ''}>

            <InputLabel theme={theme}>{label}</InputLabel>

            {(!readOnly || label === 'Estado') ? setInput() : <ReadOnlyComponent theme={theme}>{value}</ReadOnlyComponent>}

            {(readOnly) && clearButton && clearButton}

            {/* {incomplete && (<InputIncomplete>Campo obligatorio</InputIncomplete>)} */}

            {description && (<InputDescription theme={theme}>{description}</InputDescription>)}

        </InputContainer>


    )
}


