import { finishLoading, startLoading } from "../actions/ui";
import { showModalMessage } from "../utils/Alerts";
import { getAddedDate } from "../utils/Utilities";
import { addDoc, collection } from "firebase/firestore";
import axios from "axios";
import { analytics, firestore } from "../firebase/firebase-config";
import { logEvent } from "firebase/analytics";

interface FormValidationsProps {
    form: string;
    setFieldIncomplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const formValidations = ({ form, setFieldIncomplete }: FormValidationsProps) => {

    let existError: boolean = false;

    setFieldIncomplete(form.trim() === '' || !form.includes('@') || !form.includes('.'));

    if (form.trim() === '' || !form.includes('@') || !form.includes('.')) {
        existError = true;
    }

    return existError;

}

/////////////////////////////////////////////////////////////////////////////////////////


export const uploadEmail = async (email: string) => {

    await addDoc(collection(firestore, 'Newsletter'), { email })
        .catch(async (e: any) => {

            let msg = ''

            if (e.response !== undefined) {

                msg = e.response.data.message

            } else {
                msg = 'Ha ocurrido un error.'
            }

            console.error({ msg, error: e });

            await axios.post(
                `${process.env.REACT_APP_CLOUD_FUNCTIONS_API}/sendEmail`, {
                from: 'ERROR WASABI MAIL',
                to: process.env.REACT_APP_SEND_EMAIL_TO,
                name: 'Yo',
                phone: '',
                text: { msg, error: JSON.stringify(e, null, 2) }
            })



        })


}


/////////////////////////////////////////////////////////////////////////////////////////

interface FormConfirmProps {
    dispatch: any;
    form: string;
    setForm: React.Dispatch<React.SetStateAction<string>>;
    setFieldIncomplete: React.Dispatch<React.SetStateAction<boolean>>;
    setVisible?: React.Dispatch<React.SetStateAction<boolean>>
}

export const handleFormConfirm = async ({ dispatch, form, setForm, setFieldIncomplete, setVisible }: FormConfirmProps) => {

    if (!formValidations({ form, setFieldIncomplete })) {

        dispatch(startLoading())

        uploadEmail(form);

        localStorage.setItem('newsletterExpire', getAddedDate(90).padEnd(17, '0'));

        setForm('');

        dispatch(finishLoading())

        logEvent(analytics, 'Click_boton_suscribirse_newsletter')

        setVisible && setVisible(false);

        showModalMessage('Listo', '¡Vas a recibir toda las novedades por mail!', 'success');


    } else {

        showModalMessage('Atención', '¡Ingresá tu mail para no perderte de nada!', 'info');

    }

}