import styled, { css } from "styled-components"
import { device, fonts } from "../styles/stylesConfig"
import { WavyBackground } from "./WavyBackground"
import { useEffect, useState } from "react"
import Slider from 'react-slick';
import { useSelector } from "react-redux";
import { getHexadecimalByPrimaryColorState, getTicketsList, getWaveColorsByPrimaryColorState } from "../utils/Utilities";
import { TicketProps } from "../interfaces/TicketInterfaces";
import MemorizedTicketCard from "./TicketCard";

const Container = styled.div`
    width: 100%;
    min-height: 80vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #111;
    margin-top: 10vh;

    &::after {
        content: '';
        position: absolute;
        margin: auto;
        width: 150%;
        height: 200px;
        left: -50%;
        top: -150px;
        background-color: #111;
        box-shadow: 50px -75px 50px 0px #111;
    }

    @media ${device.sm} {
        margin-top: 0vh;
    }
`

const Target = styled.div`
    height: 0px;
    width: 0px;
    position: absolute;
    top: -14vh;
    left: 0px;
`

const ContentContainer = styled.div`
    width: 100%;
    margin: 50px 0px 100px 0px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media ${device.sm} {
        margin: 20px;
        margin: 0px 0px 20px 0px;
    }

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    position: relative;
    margin-bottom: 70px;

    h1 {
        font-size: 42px;
        text-transform: uppercase;
        color: #BAF18D;
        letter-spacing: 5px;
        text-align: center;

        @media ${device.md} {
            font-size: 28px;
        }

        @media ${device.xs} {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        margin-bottom: 50px;
    }
`

const List = styled.div`

    // SI HAY DOS ENTRADAS

     /* width: 800px;

    @media ${device.lg} {
        width: 600px;
    }

    @media ${device.md} {
        width: 500px;
    }

    @media ${device.sm} {
        width: 380px;
    }

    @media ${device.xs} {
        width: 280px;
    }  */

    // SI HAY DOS ENTRADAS

    //////////////////////////

    // SI HAY TRES+ ENTRADAS

    width: 1280px;

    @media ${device.lg} {
        width: fit-content;
    }

`

const CardsContainer = styled.div<{ listlength: number }>`
    display: grid;
    grid-template-columns: ${props => props.listlength && `repeat(${props.listlength}, 1fr)`};

    ${props => props.listlength === 5 && css`
        grid-template-columns: repeat(6, 1fr);

        & > div:nth-of-type(1) {
            grid-column: 1 / 3;
        }

        & > div:nth-of-type(2) {
            grid-column: 3 / 5;
        }

        & > div:nth-of-type(3) {
            grid-column: 5 / 7;
        }

        & > div:nth-of-type(4) {
            grid-column: 2 / 4;
        }

        & > div:nth-of-type(5) {
            grid-column: 4 / 6;
        }

    `}

    ${props => props.listlength > 5 && css`
        grid-template-columns: repeat(3, 1fr);
    `}


    @media ${device.lg} {
        display: unset;
    }


    
`

const CardContainer = styled.div<{ alwayshow?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;
    outline: none;
    overflow: hidden;
    position: relative;

    &:hover {

        & > div {
            /* transform: rotateY(180deg); */
            transform: scale(1.05);
            & > div {
                opacity: 1;
                backdrop-filter: blur(10px);
                /* transition-delay: 0.1s; */
            }

        }

    }

    ${props => props.alwayshow && css`

        & > div {
            /* transform: rotateY(180deg); */
            /* transform: scale(1.05); */
            & > div {
                opacity: 1;
                backdrop-filter: blur(10px);
                /* transition-delay: 0.1s; */
            }

        }
`}

`

export const NextDates = () => {

    const { primaryColor } = useSelector((state: any) => state.ui);

    const getCurrentUTCTime = () => {
        const now = new Date();
        return now.toUTCString();
    };

    const [items, setItems] = useState<TicketProps[]>([
        {
            id: 1,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/060924-groove-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/060924-groove-flyer-mobile.webp`,
            primaryColor: '#3ccb0d',
            secondaryColor: '#fff',
            place: `Groove`,
            capitalPlace: `Buenos Aires`,
            location: 'Av. Santa Fe 4389 | CABA',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/location-green.webp`,
            date: 'Viernes 6 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/calendar-green.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.passline.com/eventos/0609-wasabi-fest-en-groove',
            logEvent: 'click_entrada_06/09/24_groove',
            showSince: '2024-08-28T00:59:00Z',
            showUntil: 20240906
        },
        {
            id: 2,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/060924-temperley-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/060924-temperley-flyer-mobile.webp`,
            primaryColor: '#ff77e3',
            secondaryColor: '#fff',
            place: `Auditorio Sur`,
            capitalPlace: `Buenos Aires`,
            location: 'Av. Meeks 1080 | Temperley',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/120724-rosario-location.webp`,
            date: 'Viernes 6 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/120724-rosario-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://venti.com.ar/evento/s06-09-wasabi-fest-en-temperley-auditorio-sur',
            logEvent: 'click_entrada_06/09/24_temperley',
            showSince: '2024-08-23T00:59:00Z',
            showUntil: 20240906
        },
        {
            id: 3,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/130924-rosario1-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/130924-rosario-flyer-mobile.webp`,
            primaryColor: '#de14e3',
            secondaryColor: '#fff',
            place: `Lotus Alto Rosario`,
            capitalPlace: `Santa Fe`,
            location: 'Centro Argentino y Echeverria | Rosario',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/110524-cordoba-location.webp`,
            date: 'Viernes 13 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/110524-cordoba-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.passline.com/eventos/1309-wasabi-fest-edicion-xl-en-rosario',
            logEvent: 'click_entrada_13/09/24_rosario',
            showSince: '2024-08-19T00:59:00Z',
            showUntil: 20240913
        },
        {
            id: 4,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/200924-nordelta-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/200924-nordelta-flyer-mobile.webp`,
            primaryColor: '#e5c410',
            secondaryColor: '#fff',
            place: `Domos Park Tigre`,
            capitalPlace: `Buenos Aires`,
            location: 'Santa Maria De Las Conchas 4711 | Nordelta',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/180724-groove-location.webp`,
            date: 'Viernes 20 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/180724-groove-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.passline.com/eventos/2009-wasabi-fest-viernes-dia-de-la-primavera-en-domos-park',
            logEvent: 'click_entrada_20/09/24_nordelta',
            showSince: '2024-09-13T00:59:00Z',
            showUntil: 20240920
        },
        {
            id: 5,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/210924-nordelta-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/210924-nordelta-flyer-mobile.webp`,
            primaryColor: '#fd6a9c',
            secondaryColor: '#fff',
            place: `Domos Park Tigre`,
            capitalPlace: `Buenos Aires`,
            location: 'Santa Maria De Las Conchas 4711 | Nordelta',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/280624-groove-location.webp`,
            date: 'Sabado 21 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/280624-groove-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.passline.com/eventos/2109-wasabi-fest-dia-de-la-primavera-en-domos-park',
            logEvent: 'click_entrada_21/09/24_nordelta',
            showSince: '2024-08-30T00:59:00Z',
            showUntil: 20240921
        },
        {
            id: 6,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/220924-madrid-flyer-desktop.gif`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/220924-madrid-flyer-mobile.webp`,
            primaryColor: '#e5c410',
            secondaryColor: '#fff',
            place: `Teatro Eslava`,
            capitalPlace: `Madrid`,
            location: 'Calle Arenal 11 | Madrid',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/180724-groove-location.webp`,
            date: 'Domingo 22 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/180724-groove-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://feverup.com/m/196714',
            logEvent: 'click_entrada_22/09/24_madrid',
            showSince: '2024-08-26T19:59:00Z',
            showUntil: 20240923
        },
        {
            id: 7,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/270924-aniversario1-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/270924-aniversario1-flyer-mobile.webp`,
            primaryColor: '#de14e3',
            secondaryColor: '#fff',
            place: `C ART MEDIA`,
            capitalPlace: `Buenos Aires`,
            location: 'Avenida Corrientes 6271 | CABA',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/110524-cordoba-location.webp`,
            date: 'Viernes 27 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/110524-cordoba-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.passline.com/eventos/2709-wasabi-aniversario-en-c-art-media',
            logEvent: 'click_entrada_27/09/24_aniversario',
            showSince: '2024-09-11T00:59:00Z',
            showUntil: 20240927
        },
        {
            id: 8,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/280924-uy-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/280924-uy-flyer-mobile.webp`,
            primaryColor: '#02c9d8',
            secondaryColor: '#fff',
            place: `Lotus Club`,
            capitalPlace: `Uruguay`,
            location: 'Luis E. Lecueder 11300 | Montevideo',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/180524-uruguay-location.webp`,
            date: 'Sabado 28 de septiembre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/180524-uruguay-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.cobraticket.uy/e/wasabi-708515',
            logEvent: 'click_entrada_28/09/24_lotus',
            showSince: '2024-09-03T00:59:00Z',
            showUntil: 20240928
        },
        {
            id: 9,
            flyerDesktop: `${process.env.REACT_APP_CDN}/nextdates/121024-mdq1-flyer-desktop.webp`,
            flyerMobile: `${process.env.REACT_APP_CDN}/nextdates/121024-mdq1-flyer-mobile.webp`,
            primaryColor: '#02c9d8',
            secondaryColor: '#fff',
            place: `La Carpa`,
            capitalPlace: `Mar Del Plata`,
            location: 'Ruta 11 KM 10,5 | Buenos Aires',
            locationIcon: `${process.env.REACT_APP_CDN}/nextdates/180524-uruguay-location.webp`,
            date: 'Sabado 12 de octubre 2024 | 23:55 hs',
            dateIcon: `${process.env.REACT_APP_CDN}/nextdates/180524-uruguay-calendar.webp`,
            buyIcon: `${process.env.REACT_APP_CDN}/nextdates/ticket.webp`,
            link: 'https://www.passline.com/eventos/1210-finde-largo-de-wasabi-en-mar-del-plata',
            logEvent: 'click_entrada_12/10/24_mdq',
            showSince: '2024-09-16T00:59:00Z',
            showUntil: 20241012
        },
    ]);

    const getListLength = () => {

        const list = getTicketsList(items).filter((value: TicketProps) => {

            const targetTimeUTC = new Date(value.showSince);

            const currentTimeUTC = new Date(getCurrentUTCTime());

            if (currentTimeUTC >= targetTimeUTC) {
                return value
            }
        })

        return list.length
    }

    return (

        <Container >

            <Target id="tickets" />

            <ContentContainer>

                <TitleContainer>

                    <h1
                        style={{
                            color: getHexadecimalByPrimaryColorState(primaryColor),
                            fontFamily: fonts.secondary
                        }}
                        className="utils__fade-in-fast-top"
                    >Próximas fechas</h1>


                </TitleContainer>

                {


                    <List>
                        {/*
                        <CardsContainer
                        // slidesToShow={4}
                        // vertical={false}
                        // waitForAnimate
                        // infinite={false}
                        // arrows={false}
                        // swipe={false}
                        // dots={false}
                        // pauseOnHover
                        // autoplay
                        // autoplaySpeed={4000}
                        >

                            {getTicketsList(items)[0].flyerDesktop ? (

                                getTicketsList(items).map((item: TicketProps, index: any) => {
                                    return (

                                        <CardContainer key={index.toString()}>

                                            <MemorizedTicketCard item={item} />

                                        </CardContainer>

                                    )
                                })

                            ) : <></>

                            }

                        </CardsContainer> */}

                        <CardsContainer listlength={getListLength()}>

                            {getTicketsList(items)[0].flyerDesktop ? (

                                getTicketsList(items).map((item: TicketProps, index: any) => {

                                    const targetTimeUTC = new Date(item.showSince);

                                    const currentTimeUTC = new Date(getCurrentUTCTime());

                                    if (currentTimeUTC >= targetTimeUTC) {

                                    } else return

                                    return (

                                        <CardContainer key={index.toString()}>

                                            <MemorizedTicketCard item={item} />

                                        </CardContainer>

                                    )
                                })

                            ) : <></>

                            }

                        </CardsContainer>



                    </List>

                }

            </ContentContainer>

            <WavyBackground colors={getWaveColorsByPrimaryColorState(primaryColor)} />


        </Container >

    )
}

export default NextDates;